import type {
  News,
  NewsDetail,
  NewsPagedResult,
  SlugTranslation,
} from '~/types/api'
import type { QueryParams } from '~/types/api'
import { APIFilters } from '~/utils/APIFilters'

export function createNewsRepository() {
  const { $api } = useNuxtApp()
  const { get } = baseRepository($api)

  const DOMAIN = 'news'
  const DOMAIN_TRANSLATE = 'translate'

  async function getAll(params?: QueryParams) {
    if (!params) {
      params = {
        sort: APIFilters.makeSort({ nid: 'DESC' }),
      }
    }
    return get<NewsPagedResult>([DOMAIN], { params })
  }

  async function getAllPagination(page: number, itemsPerPage: number) {
    const params = {
      page,
      itemsPerPage,
      sort: APIFilters.makeSort({ nid: 'DESC' }),
    }
    return get<NewsPagedResult>([DOMAIN], { params })
  }

  async function getByNid(nid: number) {
    return get<News>([DOMAIN, nid])
  }

  async function getBySlug(slug: string) {
    return get<NewsDetail>([DOMAIN, slug])
  }

  async function translateSlug(slug: string) {
    return get<SlugTranslation>([DOMAIN, slug, DOMAIN_TRANSLATE])
    // TODO: This was in "langPath", but I don't think it's needed anymore ['base', 'api', DOMAIN],
  }

  async function getLatestNews(isHomePage: boolean, nid?: number) {
    const page = 1
    const maxItemsPerPage = 2
    const filter = []

    if (!isHomePage && nid !== undefined) {
      filter.push(addNotEqualsFilter('nid', nid, undefined))
    }

    const params = {
      filter: APIFilters.makeFilter(filter),
      page,
      itemsPerPage: maxItemsPerPage,
      sort: APIFilters.makeSort({ releaseDate: 'DESC' }),
    }
    return get<NewsPagedResult>([DOMAIN], { params })
  }

  return {
    getAll,
    getAllPagination,
    getBySlug,
    getByNid,
    translateSlug,
    getLatestNews,
  }
}
